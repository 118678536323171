<template>
  <form-row>
    <template #left>
      <sun-form @submit="timezoneSave">
        <!--        <sun-label-group text="Timezone Programmatic">-->
        <!--          <sun-select-->
        <!--            v-if="timezoneSelectedSSP"-->
        <!--            id="current-timezone-ssp"-->
        <!--            v-model="timezoneSelectedSSP"-->
        <!--            :options="timezoneOptions"-->
        <!--            add-hex-color="orange"-->
        <!--            color-tag="gray"-->
        <!--            name="currentTimezoneSSP"-->
        <!--            placeholder="Select timezone Programmatic"-->
        <!--            close-on-select-->
        <!--            required-->
        <!--            :loading="loadingTimezone"-->
        <!--            :allow-empty="false"-->
        <!--            :text-error="i18nErrors.selectOption"-->
        <!--          />-->
        <!--        </sun-label-group>-->
        <sun-label-group text="Timezone Direct">
          <sun-select
            v-if="timezoneSelectedTP"
            id="current-timezone-TP"
            v-model="timezoneSelectedTP"
            :options="timezoneOptions"
            add-hex-color="orange"
            color-tag="gray"
            name="currentTimezoneTP"
            placeholder="Select timezone Direct"
            close-on-select
            required
            :loading="loadingTimezone"
            :allow-empty="false"
            :text-error="i18nErrors.selectOption"
          />
        </sun-label-group>
        <div class="flex mt-4 w-full">
          <save-button id="save-timezone" :loading="loadingTimezone" text="Change" />
        </div>
      </sun-form>
    </template>
  </form-row>
</template>

<script>
import { mapActions } from 'vuex';
import { Toast } from '@/model/shared/Toast';
import { ERRORS } from '@/i18n/forms/errors';
import CONFIG from '@/components/atoms/TimezoneSelector/config';
import AVAILABLE_SETTINGS from '@/model/shared/user/availableSettings';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import SaveButton from '@/components/atoms/SaveButton.vue';
import { getSettings as getSettingsTP, updateSettings as updateSettingsTP } from '@/services/modules/ThirdParty/user';
import { UPDATE_USER_SETTINGS_REQUEST } from '@/store/modules/settings/keys';
// import {
//   getSettings as getSettingsSSP,
//   updateSettings as updateSettingsSSP,
// } from '@/services/modules/dashboardSSP/user';

export default {
  name: 'TimezoneSelector',
  components: { SaveButton, FormRow },
  data() {
    return {
      loadingTimezone: false,
      settingsTP: null,
      // settingsSSP: null,
      timezoneSelectedTP: null,
      // timezoneSelectedSSP: null,
      timezoneOptions: CONFIG.timezoneOptions,
      defaultTimezone: CONFIG.defaultTimezone,
      i18nErrors: ERRORS.en,
    };
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      updateUserSettings: UPDATE_USER_SETTINGS_REQUEST,
    }),
    async loadSettings() {
      this.loadingTimezone = true;
      try {
        this.settingsTP = await getSettingsTP('get-user-settings-tp-from-profile');
        // this.settingsSSP = await getSettingsSSP('get-user-settings-ssp-from-profile');
        this.initValues(this.settingsTP, 'timezoneSelectedTP', updateSettingsTP);
        // this.initValues(this.settingsSSP, 'timezoneSelectedSSP', updateSettingsSSP);
      } catch (error) {
        this.createToast(Toast.error('Timezones not loaded', error.message));
      } finally {
        this.loadingTimezone = false;
      }
    },
    initValues(settings, timezoneSelected, funcUpdateSettings) {
      let selected = this.validateTimezoneByDefault();
      if (settings.hasContext(CONFIG.indexName)) {
        selected = settings.getContext(CONFIG.indexName);
      } else {
        const newSettings = settings.clone();
        newSettings.config[AVAILABLE_SETTINGS.TIMEZONE] = selected;
        funcUpdateSettings(newSettings);
      }
      this[timezoneSelected] = selected;
    },
    validateTimezoneByDefault() {
      // const GMT = new Date().getTimezoneOffset() / -60;
      // if (GMT >= -9 && GMT <= -4) {
      //   return CONFIG.timezoneNewYork;
      // }

      return this.defaultTimezone;
    },
    async timezoneSave({ valid }) {
      if (!valid) return;

      try {
        this.loadingTimezone = true;
        await this.updateTimezone(this.timezoneSelectedTP);
        this.createToast(Toast.success('Timezones updated', `The timezones has been updated successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Timezones not updated', error.message));
      } finally {
        this.loadingTimezone = false;
      }
    },
    async updateTimezone(timezoneTP) {
      const newSettingsTP = this.settingsTP.clone();
      newSettingsTP.config[AVAILABLE_SETTINGS.TIMEZONE] = timezoneTP;
      await updateSettingsTP(newSettingsTP);

      this.updateUserSettings(newSettingsTP);

      // const newSettingsSSP = this.settingsSSP.clone();
      // newSettingsSSP.config[AVAILABLE_SETTINGS.TIMEZONE] = timezoneSSP;
      // await updateSettingsSSP(newSettingsSSP);
    },
  },
};
</script>

<style scoped></style>
