var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "container mx-auto flex flex-col justify-start items-start w-full mb-2 md:mt-1 xl:mt-4 px-6 xl:px-12 py-4 xl:py-12 bg-white rounded-lg shadow-md text-left",
    },
    [
      _c("div", { staticClass: "relative w-full flex flex-col" }, [
        _c(
          "div",
          { staticClass: "w-full mx-auto mt-1 xl:mt-2 text-left" },
          [
            _c(
              "h2",
              {
                staticClass:
                  "text-2xl text-left font-bold leading-tight text-orange-500 md:text-3xl md:leading-9 md:truncate",
              },
              [_vm._v(" " + _vm._s(_vm.user.name) + " ")]
            ),
            _vm.user.role
              ? [
                  _c(
                    "sun-badge",
                    {
                      staticClass: "text-xs xl:text-sm ml-2",
                      attrs: { color: "yellow" },
                    },
                    [_vm._v(" " + _vm._s(_vm.user.role) + " ")]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "flex text-left text-sm mt-2 pr-4 xl:pr-40" },
          [_vm._v(" " + _vm._s(_vm.i18nTexts.info) + " ")]
        ),
      ]),
      _c("div", { staticClass: "flex flex-col xl:flex-row w-full mt-8" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-1 flex flex-col overflow-hidden mr-0 xl:mr-8 xl:mr-16",
          },
          [
            _c(
              "card-form",
              [
                _c("template", { slot: "title" }, [
                  _vm._v(" " + _vm._s(_vm.i18nTexts.form.title) + " "),
                ]),
                _c(
                  "template",
                  { slot: "form" },
                  [
                    _c(
                      "sun-form",
                      { on: { submit: _vm.save } },
                      [
                        _c("form-row", {
                          scopedSlots: _vm._u([
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Name" } },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          id: "edit-profile",
                                          type: "text",
                                          value: _vm.userName,
                                          name: "name",
                                          required: "required",
                                          minlength: 3,
                                          maxlength: 50,
                                          placeholder: "Name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Email" } },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          id: `edit-email`,
                                          value: _vm.userEmail,
                                          type: "email",
                                          name: "email",
                                          required: "required",
                                          disabled: "",
                                          readonly: "",
                                          placeholder: "user@agency.com",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c(
                          "div",
                          { staticClass: "flex mt-4 w-full" },
                          [
                            _c("save-button", {
                              attrs: {
                                id: "button-profile",
                                loading: _vm.loading,
                                text: _vm.i18nTexts.form.saveButton,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "card-form",
              { staticClass: "mb-10" },
              [
                _c("template", { slot: "title" }, [_vm._v("Reset Password")]),
                _c(
                  "template",
                  { slot: "form" },
                  [
                    _c(
                      "sun-form",
                      { on: { submit: _vm.passwordSave } },
                      [
                        _c("form-row", {
                          scopedSlots: _vm._u([
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Current Password" } },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          id: "current-password",
                                          type: "password",
                                          "min-strength": 0,
                                          name: "currentPassword",
                                          required: "required",
                                          placeholder: "Current Password",
                                        },
                                        model: {
                                          value: _vm.currentPassword,
                                          callback: function ($$v) {
                                            _vm.currentPassword = $$v
                                          },
                                          expression: "currentPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("asterix-new-password-input", {
                          attrs: {
                            required: "required",
                            "auto-validate": _vm.autoValidatePassword,
                          },
                          on: {
                            change: function ($event) {
                              _vm.autoValidatePassword = true
                            },
                          },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "flex mt-4 w-full" },
                          [
                            _c("save-button", {
                              attrs: {
                                id: "button-password",
                                loading: _vm.loadingPassword,
                                text: "Change",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("card-form", {
              staticClass: "mb-10",
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Timezones")]
                  },
                  proxy: true,
                },
                {
                  key: "form",
                  fn: function () {
                    return [_c("timezone-selector")]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        !_vm.isSellerInDssp
          ? _c("div", { staticClass: "w-auto text-center xl:mt-8 max-w-xs" }, [
              _c(
                "div",
                {
                  staticClass:
                    "inline-flex flex-col items-center justify-center mt-4 xl:mt-0 p-8 rounded-lg bg-gray-200 shadow-lg",
                },
                [
                  _c(
                    "div",
                    { staticClass: "bg-gray-400 rounded-full px-4 py-4" },
                    [
                      _c(_vm.icon, {
                        tag: "component",
                        staticClass: "w-8 text-gray-700",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-base text-gray-800 mt-4 font-bold cursor-default",
                    },
                    [_vm._v(_vm._s(_vm.user.name))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-sm text-gray-700 mt-px font-bold",
                      attrs: { href: `mailto:${_vm.user.email}` },
                    },
                    [_vm._v(" " + _vm._s(_vm.user.email) + " ")]
                  ),
                  _vm.newActions.length > 0
                    ? [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-sm text-gray-700 mt-4 mb-2 text-right font-bold",
                          },
                          [_vm._v(_vm._s(_vm.i18nTexts.contexts) + ": ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex flex-wrap items-center justify-center",
                          },
                          [
                            _vm._l(_vm.user.contextRoles, function (context) {
                              return [
                                _c(
                                  "sun-badge",
                                  {
                                    key: context.context,
                                    staticClass:
                                      "sun-badge-item px-1 m-1 text-xs text-gray-500 whitespace-no-wrap truncate",
                                    attrs: { color: "orange" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.transformNameToValue(
                                            context.context
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }