var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-row", {
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function () {
          return [
            _c(
              "sun-form",
              { on: { submit: _vm.timezoneSave } },
              [
                _c(
                  "sun-label-group",
                  { attrs: { text: "Timezone Direct" } },
                  [
                    _vm.timezoneSelectedTP
                      ? _c("sun-select", {
                          attrs: {
                            id: "current-timezone-TP",
                            options: _vm.timezoneOptions,
                            "add-hex-color": "orange",
                            "color-tag": "gray",
                            name: "currentTimezoneTP",
                            placeholder: "Select timezone Direct",
                            "close-on-select": "",
                            required: "",
                            loading: _vm.loadingTimezone,
                            "allow-empty": false,
                            "text-error": _vm.i18nErrors.selectOption,
                          },
                          model: {
                            value: _vm.timezoneSelectedTP,
                            callback: function ($$v) {
                              _vm.timezoneSelectedTP = $$v
                            },
                            expression: "timezoneSelectedTP",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex mt-4 w-full" },
                  [
                    _c("save-button", {
                      attrs: {
                        id: "save-timezone",
                        loading: _vm.loadingTimezone,
                        text: "Change",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }