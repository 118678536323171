<template>
  <div
    class="container mx-auto flex flex-col justify-start items-start w-full mb-2 md:mt-1 xl:mt-4 px-6 xl:px-12 py-4 xl:py-12 bg-white rounded-lg shadow-md text-left"
  >
    <div class="relative w-full flex flex-col">
      <div class="w-full mx-auto mt-1 xl:mt-2 text-left">
        <h2 class="text-2xl text-left font-bold leading-tight text-orange-500 md:text-3xl md:leading-9 md:truncate">
          {{ user.name }}
        </h2>
        <template v-if="user.role">
          <sun-badge color="yellow" class="text-xs xl:text-sm ml-2">
            {{ user.role }}
          </sun-badge>
        </template>
      </div>
      <div class="flex text-left text-sm mt-2 pr-4 xl:pr-40">
        {{ i18nTexts.info }}
      </div>
    </div>
    <div class="flex flex-col xl:flex-row w-full mt-8">
      <div class="flex-1 flex flex-col overflow-hidden mr-0 xl:mr-8 xl:mr-16">
        <card-form>
          <template slot="title">
            {{ i18nTexts.form.title }}
          </template>
          <template slot="form">
            <sun-form @submit="save">
              <form-row>
                <template #left>
                  <sun-label-group text="Name">
                    <asterix-input
                      id="edit-profile"
                      type="text"
                      :value="userName"
                      name="name"
                      required="required"
                      :minlength="3"
                      :maxlength="50"
                      placeholder="Name"
                    />
                  </sun-label-group>
                </template>
                <template #right>
                  <sun-label-group text="Email">
                    <asterix-input
                      :id="`edit-email`"
                      :value="userEmail"
                      type="email"
                      name="email"
                      required="required"
                      disabled
                      readonly
                      placeholder="user@agency.com"
                    />
                  </sun-label-group>
                </template>
              </form-row>
              <div class="flex mt-4 w-full">
                <save-button id="button-profile" :loading="loading" :text="i18nTexts.form.saveButton" />
              </div>
            </sun-form>
          </template>
        </card-form>

        <card-form class="mb-10">
          <template slot="title">Reset Password</template>
          <template slot="form">
            <sun-form @submit="passwordSave">
              <form-row>
                <template #left>
                  <sun-label-group text="Current Password">
                    <asterix-input
                      id="current-password"
                      v-model="currentPassword"
                      type="password"
                      :min-strength="0"
                      name="currentPassword"
                      required="required"
                      placeholder="Current Password"
                    />
                  </sun-label-group>
                </template>
              </form-row>
              <asterix-new-password-input
                v-model="newPassword"
                required="required"
                :auto-validate="autoValidatePassword"
                @change="autoValidatePassword = true"
              />
              <div class="flex mt-4 w-full">
                <save-button id="button-password" :loading="loadingPassword" text="Change" />
              </div>
            </sun-form>
          </template>
        </card-form>

        <card-form class="mb-10">
          <template #title>Timezones</template>
          <template #form>
            <timezone-selector />
          </template>
        </card-form>
      </div>
      <div v-if="!isSellerInDssp" class="w-auto text-center xl:mt-8 max-w-xs">
        <div class="inline-flex flex-col items-center justify-center mt-4 xl:mt-0 p-8 rounded-lg bg-gray-200 shadow-lg">
          <div class="bg-gray-400 rounded-full px-4 py-4">
            <component :is="icon" class="w-8 text-gray-700" />
          </div>
          <span class="text-base text-gray-800 mt-4 font-bold cursor-default">{{ user.name }}</span>
          <a :href="`mailto:${user.email}`" class="text-sm text-gray-700 mt-px font-bold">
            {{ user.email }}
          </a>
          <template v-if="newActions.length > 0">
            <span class="text-sm text-gray-700 mt-4 mb-2 text-right font-bold">{{ i18nTexts.contexts }}: </span>
            <div class="w-full flex flex-wrap items-center justify-center">
              <template v-for="context in user.contextRoles">
                <sun-badge
                  :key="context.context"
                  color="orange"
                  class="sun-badge-item px-1 m-1 text-xs text-gray-500 whitespace-no-wrap truncate"
                >
                  {{ transformNameToValue(context.context) }}
                </sun-badge>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { ERRORS } from '@/i18n/forms/errors';
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import { capitalize } from '@/utils/text/capitalize';
import { updateProfile } from '@/services/modules/Core/profile';
import { loggedInResetPassword } from '@/services/modules/Auth';
import { PERMISSIONS, UPDATE_USER, USER } from '@/store/modules/auth/keys';
import { ICONS } from '@/model/shared/icons';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import SaveButton from '@/components/atoms/SaveButton';
import metaInfo from '@/mixins/common/metaInfo';
import { getNewActions } from './helper';
import AsterixNewPasswordInput from '@/components/atoms/AsterixNewPasswordInput';
import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import TimezoneSelector from '@/components/atoms/TimezoneSelector/TimezoneSelector.vue';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';

export default {
  name: 'EditProfile',
  components: {
    TimezoneSelector,
    FormRow,
    SaveButton,
    CardForm,
    AsterixInput,
    AsterixNewPasswordInput,
  },
  mixins: [metaInfo],
  data: () => ({
    i18nErrors: ERRORS.en,
    i18nTexts: COMMON_TEXTS.en.profile,
    errorsForm: { email: null, password: null },
    loading: false,
    newActions: [],
    loadingPassword: false,
    userName: null,
    userEmail: null,
    currentPassword: '',
    newPassword: '',
    autoValidatePassword: false,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      actions: PERMISSIONS,
      contextActive: ACTIVE_CONTEXT,
    }),
    icon() {
      return this.user && ICONS[this.user.role] ? ICONS[this.user.role] : () => import('@/components/icons/UserSvg');
    },
    isSellerInDssp() {
      const context = this.user.contextRoles.find(context => context.context === CONTEXTS.DASHBOARD_SSP.id);
      return context && context.role === ROLES.SELLER.id;
    },
  },
  watch: {
    actions() {
      this.setNewActions();
    },
  },
  created() {
    this.setNewActions();
    this.userName = this.user.name;
    this.userEmail = this.user.email;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      reloadProfile: USER,
      updateProfileToStore: UPDATE_USER,
    }),
    metaTitleReplacement() {
      return { user: this.user?.name } || {};
    },
    transformNameToValue(param) {
      const strVal = param.toLowerCase().replace('_', ' ');
      return capitalize(strVal);
    },
    async save({ form, valid }) {
      if (!valid) return;
      try {
        this.loading = true;
        const objectToPut = {
          name: form.name.value,
          email: form.email.value,
        };
        const { data } = await updateProfile(objectToPut);
        await this.updateProfileToStore(objectToPut);
        this.createToast(Toast.success('Profile updated', data.message));
        // True to force reload profile
        this.reloadProfile(true);
      } catch (e) {
        this.createToast(Toast.error('Profile not updated', e.message));
      } finally {
        this.loading = false;
      }
    },
    async passwordSave({ valid }) {
      if (!valid) return;

      this.loadingPassword = true;
      try {
        await loggedInResetPassword(this.currentPassword, this.newPassword);
        this.createToast(Toast.success('Password updated', `The new password has been updated successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Password not updated', error.message));
      } finally {
        this.restartPasswordForm();
        this.loadingPassword = false;
      }
    },
    restartPasswordForm() {
      this.newPassword = '';
      this.currentPassword = '';
    },
    setNewActions() {
      if (Object.keys(this.actions).length > 0) {
        const actions = getNewActions(this.actions);
        this.$set(this, 'newActions', actions);
      }
    },
  },
};
</script>
